/* lib/login/auth/auth.component.scss */
.logo {
  width: 150px;
  margin: 0 auto;
  margin-top: 50px;
}
.login-container-wrapper {
  background-color: #fff;
  padding-bottom: 1rem;
}
/*# sourceMappingURL=auth.component.css.map */
