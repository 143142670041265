/* lib/login/login.component.scss */
ion-grid,
ion-row {
  height: 100%;
}
.login-button-container {
  margin-top: 20px;
  padding: 0 20px 20px;
}
.error-message {
  display: block;
  padding-top: 10px;
  padding-left: 20px;
}
.width-100 {
  width: 100%;
}
.logo {
  width: 150px;
  margin: 0 auto;
  margin-top: 50px;
}
.logout-link {
  cursor: pointer;
  color: #494f91;
  text-decoration: underline;
}
.login-spinner-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forgotten-password {
  text-align: center;
}
.forgotten-password a {
  margin: 0 auto;
}
.version-number {
  background-color: #fff;
}
/*# sourceMappingURL=login.component.css.map */
