/* lib/settings/settings.component.scss */
[ion-fixed] {
  width: 100%;
  height: 100%;
}
.profile {
  background-color: var(--ion-color-primary);
}
.profile * {
  color: var(--ion-color-primary-contrast);
}
.logo {
  padding-top: 30px;
  width: 150px;
  margin: 0 auto;
}
ion-card {
  color: var(--ion-color-primary);
}
/*# sourceMappingURL=settings.component.css.map */
