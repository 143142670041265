/* lib/login/create-account/create-licence/create-licence.component.scss */
ion-item-divider {
  color: var(--ion-color-primary);
  font-weight: 500;
}
.error-message {
  display: block;
  padding-top: 10px;
  padding-left: 20px;
}
/*# sourceMappingURL=create-licence.component.css.map */
