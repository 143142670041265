/* apps/outdoorPro/src/app/shell/shell.component.scss */
ion-tab-bar {
  background-color: var(--ion-color-primary-shade);
  height: 60px;
}
.bar-logo {
  width: 75%;
  padding: 10px 0;
}
@media screen and (max-width: 767px) {
  .bar-logo {
    display: none;
  }
}
ion-tab-button {
  background-color: var(--ion-color-primary-shade);
  transition: 0.3s all;
}
ion-tab-button ion-label {
  color: #fff;
  font-size: 10px;
}
ion-tab-button svg {
  color: #fff;
  width: 35px;
  height: 35px;
}
ion-tab-button.tab-selected {
  background-color: var(--ion-color-primary);
}
ion-tab-button.tab-selected svg {
  width: 35px;
  height: 35px;
}
@media (min-width: 768px) {
  ion-tabs {
    flex-direction: row-reverse;
  }
  ion-tab-bar {
    height: 100%;
    width: 90px;
    flex-direction: column;
    justify-content: flex-start;
    border-top: none;
    border-right: var(--border);
  }
  ion-tab-button {
    width: 100%;
    height: 90px;
    flex: 0 1 auto;
  }
}
.icon {
  height: 40px;
}
/*# sourceMappingURL=shell.component.css.map */
