/* lib/login/create-account/create-user/create-user.component.scss */
.logo {
  width: 150px;
  margin: 0 auto;
  margin-top: 50px;
}
.error-message {
  display: block;
  padding-top: 10px;
  padding-left: 20px;
}
/*# sourceMappingURL=create-user.component.css.map */
