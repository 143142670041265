/* lib/settings/licence/licence.component.scss */
ion-card {
  color: var(--ion-color-primary);
}
h2 {
  margin-left: 1rem;
  font-size: 1.2rem;
}
.header {
  display: flex;
  justify-content: space-between;
}
/*# sourceMappingURL=licence.component.css.map */
