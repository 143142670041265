import { isPlatformBrowser } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, PLATFORM_ID, Injectable, Optional, Inject, NgModule } from '@angular/core';
import * as i1 from '@angular/fire/compat';
import { ɵcacheInstance as _cacheInstance, ɵlazySDKProxy as _lazySDKProxy, ɵapplyMixins as _applyMixins } from '@angular/fire/compat';
import { of, EMPTY, Observable } from 'rxjs';
import { switchMap, map, shareReplay, tap, first } from 'rxjs/operators';
import { VERSION } from '@angular/fire';
import firebase from 'firebase/compat/app';

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
// Export a null object with the same keys as firebase/compat/performance, so Proxy can work with proxy-polyfill in Internet Explorer
const proxyPolyfillCompat = {
  app: null,
  trace: null,
  instrumentationEnabled: null,
  dataCollectionEnabled: null
};
const INSTRUMENTATION_ENABLED = new InjectionToken('angularfire2.performance.instrumentationEnabled');
const DATA_COLLECTION_ENABLED = new InjectionToken('angularfire2.performance.dataCollectionEnabled');
class AngularFirePerformance {
  zone;
  performance;
  constructor(app, instrumentationEnabled, dataCollectionEnabled, zone,
  // eslint-disable-next-line @typescript-eslint/ban-types
  platformId) {
    this.zone = zone;
    this.performance = of(undefined).pipe(switchMap(() => isPlatformBrowser(platformId) ? zone.runOutsideAngular(() => import('firebase/compat/performance')) : EMPTY), map(() => _cacheInstance(`performance`, 'AngularFirePerformance', app.name, () => {
      const performance = zone.runOutsideAngular(() => app.performance());
      if (instrumentationEnabled === false) {
        performance.instrumentationEnabled = false;
      }
      if (dataCollectionEnabled === false) {
        performance.dataCollectionEnabled = false;
      }
      return performance;
    }, [instrumentationEnabled, dataCollectionEnabled])), shareReplay({
      bufferSize: 1,
      refCount: false
    }));
    return _lazySDKProxy(this, this.performance, zone);
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "18.0.0",
    ngImport: i0,
    type: AngularFirePerformance,
    deps: [{
      token: i1.FirebaseApp
    }, {
      token: INSTRUMENTATION_ENABLED,
      optional: true
    }, {
      token: DATA_COLLECTION_ENABLED,
      optional: true
    }, {
      token: i0.NgZone
    }, {
      token: PLATFORM_ID
    }],
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "18.0.0",
    ngImport: i0,
    type: AngularFirePerformance,
    providedIn: 'any'
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.0",
  ngImport: i0,
  type: AngularFirePerformance,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'any'
    }]
  }],
  ctorParameters: () => [{
    type: i1.FirebaseApp
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [INSTRUMENTATION_ENABLED]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [DATA_COLLECTION_ENABLED]
    }]
  }, {
    type: i0.NgZone
  }, {
    type: Object,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }]
});
const trace$ = traceId => {
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (typeof window !== 'undefined' && window.performance?.mark) {
    const entries = window.performance.getEntriesByName(traceId, 'measure') || [];
    const startMarkName = `_${traceId}Start[${entries.length}]`;
    const endMarkName = `_${traceId}End[${entries.length}]`;
    return new Observable(emitter => {
      window.performance.mark(startMarkName);
      emitter.next();
      return {
        unsubscribe: () => {
          window.performance.mark(endMarkName);
          window.performance.measure(traceId, startMarkName, endMarkName);
        }
      };
    });
  } else {
    return EMPTY;
  }
};
const traceUntil = (name, test, options) => source$ => new Observable(subscriber => {
  const traceSubscription = trace$(name).subscribe();
  return source$.pipe(tap(a => test(a) && traceSubscription.unsubscribe(), () => undefined, () => options && options.orComplete && traceSubscription.unsubscribe())).subscribe(subscriber);
});
const traceWhile = (name, test, options) => source$ => new Observable(subscriber => {
  let traceSubscription;
  return source$.pipe(tap(a => {
    if (test(a)) {
      traceSubscription = traceSubscription || trace$(name).subscribe();
    } else {
      if (traceSubscription) {
        traceSubscription.unsubscribe();
      }
      traceSubscription = undefined;
    }
  }, () => undefined, () => options && options.orComplete && traceSubscription && traceSubscription.unsubscribe())).subscribe(subscriber);
});
const traceUntilComplete = name => source$ => new Observable(subscriber => {
  const traceSubscription = trace$(name).subscribe();
  return source$.pipe(tap(() => undefined, () => undefined, () => traceSubscription.unsubscribe())).subscribe(subscriber);
});
const traceUntilFirst = name => source$ => new Observable(subscriber => {
  const traceSubscription = trace$(name).subscribe();
  return source$.pipe(tap(() => traceSubscription.unsubscribe(), () => undefined, () => undefined)).subscribe(subscriber);
});
const trace = name => source$ => new Observable(subscriber => {
  const traceSubscription = trace$(name).subscribe();
  return source$.pipe(tap(() => traceSubscription.unsubscribe(), () => undefined, () => traceSubscription.unsubscribe())).subscribe(subscriber);
});
_applyMixins(AngularFirePerformance, [proxyPolyfillCompat]);
const IS_STABLE_START_MARK = 'Zone';
const IS_STABLE_END_MARK = '_isStableEnd';
class PerformanceMonitoringService {
  disposable;
  constructor(appRef) {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (typeof window !== 'undefined' && window.performance?.mark) {
      this.disposable = appRef.isStable.pipe(first(it => it), tap(() => {
        window.performance.mark(IS_STABLE_END_MARK);
        window.performance.measure('isStable', IS_STABLE_START_MARK, IS_STABLE_END_MARK);
      })).subscribe();
    }
  }
  ngOnDestroy() {
    if (this.disposable) {
      this.disposable.unsubscribe();
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "18.0.0",
    ngImport: i0,
    type: PerformanceMonitoringService,
    deps: [{
      token: i0.ApplicationRef
    }],
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "18.0.0",
    ngImport: i0,
    type: PerformanceMonitoringService
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.0",
  ngImport: i0,
  type: PerformanceMonitoringService,
  decorators: [{
    type: Injectable
  }],
  ctorParameters: () => [{
    type: i0.ApplicationRef
  }]
});
class AngularFirePerformanceModule {
  constructor(perf, _) {
    firebase.registerVersion('angularfire', VERSION.full, 'perf-compat');
    // call anything here to get perf loading
    perf.dataCollectionEnabled.then(() => undefined);
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "18.0.0",
    ngImport: i0,
    type: AngularFirePerformanceModule,
    deps: [{
      token: AngularFirePerformance
    }, {
      token: PerformanceMonitoringService,
      optional: true
    }],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "18.0.0",
    ngImport: i0,
    type: AngularFirePerformanceModule
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "18.0.0",
    ngImport: i0,
    type: AngularFirePerformanceModule,
    providers: [AngularFirePerformance]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.0",
  ngImport: i0,
  type: AngularFirePerformanceModule,
  decorators: [{
    type: NgModule,
    args: [{
      providers: [AngularFirePerformance]
    }]
  }],
  ctorParameters: () => [{
    type: AngularFirePerformance
  }, {
    type: PerformanceMonitoringService,
    decorators: [{
      type: Optional
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { AngularFirePerformance, AngularFirePerformanceModule, DATA_COLLECTION_ENABLED, INSTRUMENTATION_ENABLED, PerformanceMonitoringService, trace, traceUntil, traceUntilComplete, traceUntilFirst, traceWhile };
