/* lib/login/verify-email/verify-email.component.scss */
.logo {
  width: 150px;
  margin: 0 auto;
  margin-top: 50px;
}
.login-container-wrapper {
  background-color: #fff;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.login-spinner-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*# sourceMappingURL=verify-email.component.css.map */
